import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Logo, { LogoType } from 'components/Logo';

const Header: FC<{ logoType?: LogoType | null; intl: IntlShape }> = (props) => {
  const {
    logoType,
    intl: { formatMessage },
  } = props;

  return (
    <div className="header">
      {logoType && <Logo type={logoType} />}
      <h1 className="header-text">
        {formatMessage({ id: 'heading.verifyYourIdentity' })}
      </h1>
    </div>
  );
};

export default injectIntl(Header);
