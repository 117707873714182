import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import EDDRoot from 'modules/enhanced-due-diligence/pages/Root';
import { LogoType } from '../Logo';

const AppRouter: FC<{ rootErrors?: string[]; logoType?: LogoType | null }> = ({
  rootErrors,
  logoType,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<EDDRoot rootErrors={rootErrors} logoType={logoType} />}
      />
    </Routes>
  );
};

export default AppRouter;
