import { FC, useState } from 'react';
import ProgressBar from 'components/ProgressBar';
import Question, {
  QuestionData,
} from 'modules/enhanced-due-diligence/components/Question';

interface QuestionnaireProps {
  onSubmit: () => void;
  questions: QuestionData[];
}

const Questionnaire: FC<QuestionnaireProps> = ({ onSubmit, questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const isLastIndex = questions.length - 1 === currentQuestionIndex;

  const setIndexAndRedirectFinally = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    if (isLastIndex) onSubmit();
  };

  return (
    <>
      <ProgressBar
        totalStepCount={questions.length}
        currentStepIndex={currentQuestionIndex}
      />
      <Question
        data={questions[currentQuestionIndex]}
        onSubmit={setIndexAndRedirectFinally}
        key={currentQuestionIndex}
      />
    </>
  );
};

export default Questionnaire;
