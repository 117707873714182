import { FC } from 'react';
import classNames from 'classnames';
import CircleCheck from 'components/Icon/components/CircleCheck';
import ExclamationTriangle from 'components/Icon/components/ExclamationTriangle';
import Info from 'components/Icon/components/Info';
import TrashCan from 'components/Icon/components/TrashCan';
import Upload from 'components/Icon/components/Upload';

const baseClassName = 'icon';

export enum IconType {
  ExclamationTriangle = 'ExclamationTriangle',
  Upload = 'Upload',
  CircleCheck = 'CircleCheck',
  TrashCan = 'TrashCan',
  Info = 'Info',
}

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum IconColor {
  RED = 'red',
  GREY = 'grey',
  GREEN = 'green',
}

export interface IconProps {
  type: IconType;
  className?: string;
  color?: IconColor;
  size?: IconSize;
}

const Icon: FC<IconProps> = (props) => {
  const { type, size = 'medium', color, className } = props;
  const iconClassNames = classNames(
    baseClassName,
    `${baseClassName}--${size}`,
    {
      [`${baseClassName}--${color}`]: !!color,
      [`${className}`]: !!className,
    },
  );

  const component = () => {
    switch (type) {
      case IconType.ExclamationTriangle:
        return <ExclamationTriangle />;
      case IconType.Upload:
        return <Upload />;
      case IconType.CircleCheck:
        return <CircleCheck />;
      case IconType.TrashCan:
        return <TrashCan />;
      case IconType.Info:
        return <Info />;
    }
  };

  return (
    <i className={iconClassNames} data-testid={type}>
      {component()}
    </i>
  );
};

export default Icon;
