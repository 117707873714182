import { Dictionary } from 'types';

export const es: Dictionary<string> = {
  'button.submitAndProceed': 'Enviar y Continuar',
  'button.loading': 'Cargando',
  'button.start': 'Start',
  'heading.verifyYourIdentity': 'Verifica tu Identidad',
  'info.submitButton':
    "Tenga en cuenta que si hace clic en el botón 'Enviar y Continuar' después de responder una pregunta, ya no podrá editar su respuesta.",
  'input.placeholder': 'Por favor escribe una respuesta',
  'input.fileUpload': 'Haga clic aquí para cargar',
};
