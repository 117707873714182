import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { injectIntl, IntlShape } from 'react-intl';
import { request } from 'api';
import FileEntry from 'components/FileEntry';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon';
import { BaseInputProps } from 'components/InputField';
import { AllowedDocumentTypes } from 'types';
import { v4 as uuidv4 } from 'uuid';

const baseClassName = 'file-input';
export const testId = 'file-input';

export type FileInputProps = BaseInputProps & {
  id: string;
  allowedDocumentTypes?: AllowedDocumentTypes[];
};

export interface FileObject {
  id?: string;
  file: File;
  isLoading?: boolean;
  error?: string;
}

const FileInput: FC<FileInputProps & { intl: IntlShape }> = (props) => {
  const {
    id: questionId,
    control,
    register,
    setIsCustomValid,
    allowedDocumentTypes,
    intl: { formatMessage },
  } = props;

  // fileObjects is what will be visible on the page
  const [fileObjects, setFileObjects] = useState<{ [key: string]: FileObject }>(
    {},
  );

  // fields determine what will be submitted as the answer
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${questionId}.attachments`,
  });

  useEffect(() => {
    const areThereFilesInState = Object.values(fileObjects).length > 0;
    const areAllFilesUploaded = Object.values(fileObjects).every(
      (fileObj: FileObject) => Boolean(fileObj.id),
    );

    setIsCustomValid &&
      setIsCustomValid(areThereFilesInState && areAllFilesUploaded);
  }, [fileObjects, setIsCustomValid]);

  // Helper methods
  const updateKeyInCurrStateWith = (
    key: string,
    newValues: Partial<FileObject>,
  ) =>
    setFileObjects((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...newValues },
    }));

  // Callback functions
  const onRemoveFile = (key: string) => {
    const { id } = fileObjects[key];
    id && remove(fields.findIndex((field) => field['attachments'] === id));

    const { [key]: _, ...rest } = fileObjects;
    setFileObjects(rest);
  };

  const onAddFile = (key: string, file: File) => {
    setFileObjects((prev) => ({ ...prev, [key]: { file, isLoading: true } }));

    const requestBody = new FormData();
    requestBody.append('file', file);
    requestBody.append('document_type', allowedDocumentTypes?.[0] || 'OTHER');

    request({
      method: 'post',
      path: '/documents',
      requestBody,
      successCallback: (data: { id: string }) => {
        append(data.id);
        updateKeyInCurrStateWith(key, { id: data.id, isLoading: false });
      },
      errorCallback: (error: string) =>
        updateKeyInCurrStateWith(key, { isLoading: false, error }),
    });
  };

  const onfileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files || []).forEach((file: File) => {
      const uploadedFiles = Object.values(fileObjects);

      if (uploadedFiles.findIndex((f) => f.file.name === file.name) === -1) {
        onAddFile(uuidv4(), file);
      }
      e.target.value = '';
    });
  };

  return (
    <>
      {Object.keys(fileObjects).map((key) => (
        <FileEntry
          fileObject={fileObjects[key]}
          key={key}
          fileKey={key}
          onRemoveFile={onRemoveFile}
          onRetry={onAddFile}
        />
      ))}
      <label className={`${baseClassName}__wrapper`}>
        {Object.keys(fileObjects).length > 0 ? (
          <span className={`${baseClassName}__text`}>
            {formatMessage({ id: 'input.fileUploadHidden' })}
          </span>
        ) : (
          <>
            <div className={`${baseClassName}`}>
              <div className={`${baseClassName}__icon`}>
                <Icon
                  type={IconType.Upload}
                  color={IconColor.GREY}
                  size={IconSize.SMALL}
                />
              </div>
            </div>
            <span className={`${baseClassName}__text`}>
              {formatMessage({ id: 'input.fileUpload' })}
            </span>
          </>
        )}
        <input
          type="file"
          multiple
          data-testid={testId}
          className={`${baseClassName}__file-input`}
          onChange={onfileInputChange}
        />
      </label>
      {/* Hidden input fields thay hold the solaris document ids as value. 
      The values registered here will be submitted to the answer endpoint */}
      {fields.map((field, i) => (
        <input
          key={field.id}
          type="hidden"
          {...(register && register(`${questionId}.attachments.${i}`))}
        />
      ))}
    </>
  );
};

export default injectIntl(FileInput);
