import { Dictionary } from 'types';

export const fr: Dictionary<string> = {
  'button.submitAndProceed': 'Soumettre et Continuer',
  'button.loading': 'Chargement',
  'button.start': 'Start',
  'heading.verifyYourIdentity': 'Vérifiez Votre Identité',
  'info.submitButton':
    'Veuillez noter que si vous cliquez sur le bouton «Soumettre et Continuer» après avoir répondu à une question, vous ne pourrez plus modifier votre réponse.',
  'input.placeholder': 'Veuillez saisir une réponse',
  'input.fileUpload': 'Cliquez ici pour télécharger',
};
