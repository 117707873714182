import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Logo, { LogoSize, LogoType } from 'components/Logo';

const Footer: FC<{ intl: IntlShape }> = (props) => {
  const {
    intl: { formatMessage },
  } = props;
  return (
    <div className="footer">
      <span className="footer-text">
        {formatMessage({ id: 'footer.poweredBy' })}
      </span>
      <Logo type={LogoType.Solaris} size={LogoSize.SMALL} inline />
      <br />
      <br />
      <a
        className="footer-text footer-link"
        href="https://www.solarisgroup.com/en/imprint/"
        target="_blank"
        rel="noreferrer"
      >
        Imprint
      </a>
    </div>
  );
};

export default injectIntl(Footer);
