import { ChangeEvent } from 'react';
import { Control, useForm } from 'react-hook-form';

export const inputBaseClassName = 'input';

export interface BaseInputProps {
  id: string;
  placeholder?: string;
  value?: string | number;
  register?: ReturnType<typeof useForm>['register'];
  setIsCustomValid?: (value: boolean) => void;
  control?: Control;
  hasError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}
