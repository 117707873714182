import { FC, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthenticationProvider from 'components/AuthenticationProvider';
import AppRouter from 'components/Router';
import ThemeProvider from 'components/ThemeProvider';
import * as messages from 'messages';
import { initiateSentry } from 'utils/errorReporter';
import { LogoType } from '../Logo';

initiateSentry();
enum localeOption {
  EN = 'en',
  DE = 'de',
  // FR = 'fr',
  // IT = 'it',
  // ES = 'es',
}

const App: FC<{}> = () => {
  const [rootErrors, setRootError] = useState<string[]>([]);
  const [logoType, setLogoType] = useState<LogoType | null>(null);
  const [userLocale, setUserLocale] = useState(localeOption.DE);

  const router = createBrowserRouter([
    {
      path: '*',
      element: <AppRouter rootErrors={rootErrors} logoType={logoType} />,
    },
  ]);

  const errorCallback = (errMessage: string) =>
    setRootError([...rootErrors, errMessage]);

  return (
    <IntlProvider
      locale={userLocale}
      messages={messages[userLocale]}
      defaultLocale="de"
    >
      <AuthenticationProvider errorCallback={errorCallback}>
        <ThemeProvider
          setLogoType={(logo?: LogoType) => logo && setLogoType(logo)}
          setLocale={(language?: localeOption) =>
            language && setUserLocale(localeOption[language])
          }
        >
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthenticationProvider>
    </IntlProvider>
  );
};

export default App;
