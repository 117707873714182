import { Dictionary } from 'types';

export const it: Dictionary<string> = {
  'button.submitAndProceed': 'Invia e Procedi',
  'button.loading': 'Caricamento',
  'button.start': 'Start',
  'heading.verifyYourIdentity': 'Verifica la tua Identità',
  'info.submitButton':
    "Tieni presente che se fai clic sul pulsante 'Invia e procedi' dopo aver risposto a una domanda non sarai più in grado di modificare la tua risposta.",
  'input.placeholder': 'Per favore digita una risposta',
  'input.fileUpload': 'Clicca qui per caricare',
};
