export interface Dictionary<T> {
  [key: string]: T;
}

export interface RequestConfig {
  method: 'get' | 'post';
  path: string;
  requestBody?: {} | FormData;
  shouldRetryOnAuthError?: boolean;
  successCallback?: (data: {}) => void;
  errorCallback?: (errMessage: string) => void;
}

export interface ApiError {
  id?: string;
  status?: number;
  code?: string;
  title: string;
  detail: string;
}

export type ApiErrorResponse = ApiError & { errors: ApiError[] };

export enum AllowedDocumentTypes {
  ACCOUNT_SNAPSHOT_FINTECSYSTEMS = 'ACCOUNT_SNAPSHOT.FINTECSYSTEMS',
  ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT',
  AML_POLICY = 'AML_POLICY',
  ANNUAL_FINANCIAL_STATEMENT = 'ANNUAL_FINANCIAL_STATEMENT',
  B2B_MANDATE = 'B2B_MANDATE',
  BALANCE_REPORT = 'BALANCE_REPORT',
  BANK_REFERENCE = 'BANK_REFERENCE',
  BUSINESS_ASSESSMENT = 'BUSINESS_ASSESSMENT',
  BUSINESS_FINANCIALS_XBRL = 'BUSINESS_FINANCIALS_XBRL',
  CREDIT_AGENCY_REPORT = 'CREDIT_AGENCY_REPORT',
  CREDIT_RECORD_SCHUFA = 'CREDIT_RECORD.SCHUFA',
  CUSTOMER_COMMUNICATION = 'CUSTOMER_COMMUNICATION',
  FATCA_SELF_DECLARATION = 'FATCA_SELF_DECLARATION',
  FOUNDATION_DOCUMENT = 'FOUNDATION_DOCUMENT',
  ID_DOCUMENT = 'ID_DOCUMENT',
  INVOICE = 'INVOICE',
  KYC_BENEFICIAL_OWNER = 'KYC_BENEFICIAL_OWNER',
  KYC_CORPORATE_DOCUMENT = 'KYC_CORPORATE_DOCUMENT',
  KYC_FORM = 'KYC_FORM',
  KYC_LEGAL_REPRESENTATIVE = 'KYC_LEGAL_REPRESENTATIVE',
  KYC_RELIABILITY_CHECK = 'KYC_RELIABILITY_CHECK',
  KYC_REPORT = 'KYC_REPORT',
  LOAN_MANDATE_CONTRACT = 'LOAN_MANDATE_CONTRACT',
  OTHER = 'OTHER',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  PICTURE = 'PICTURE',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_PHONE_CHANGE = 'PROOF_OF_PHONE_CHANGE',
  PROOF_OF_PROFESSION = 'PROOF_OF_PROFESSION',
  PROOF_OF_SALARY = 'PROOF_OF_SALARY',
  PROOF_OF_TAX_ID = 'PROOF_OF_TAX_ID',
  REFERENCE_LETTER = 'REFERENCE_LETTER',
  REGISTER_APPLICATION = 'REGISTER_APPLICATION',
  REGISTER_EXTRACT = 'REGISTER_EXTRACT',
  SALARY_INFORMATION = 'SALARY_INFORMATION',
  SCHUFA_COMPACT_REPORT = 'SCHUFA_COMPACT_REPORT',
  SCHUFA_FULL_REPORT = 'SCHUFA_FULL_REPORT',
  SCHUFA_GWG_REPORT = 'SCHUFA_GWG_REPORT',
  SCHUFA_SHORT_REPORT = 'SCHUFA_SHORT_REPORT',
  SCORE_CARD_SOLARIS = 'SCORE_CARD.SOLARIS',
  SCREENING_REPORT = 'SCREENING_REPORT',
  SHAREHOLDERS_LIST = 'SHAREHOLDERS_LIST',
  SHARE_HOLDERS_AGREEMENT = 'SHARE_HOLDERS_AGREEMENT',
  SIGNATURE = 'SIGNATURE',
  SIGNED_CONTRACT = 'SIGNED_CONTRACT',
  SIGNED_LOAN_MANDATE_CONTRACT = 'SIGNED_LOAN_MANDATE_CONTRACT',
  TRADING_LICENSE = 'TRADING_LICENSE',
  TRANSACTIONAL_DOCUMENT = 'TRANSACTIONAL_DOCUMENT',
  TRANSFER_CANCELLATION = 'TRANSFER_CANCELLATION',
  VAT_CERTIFICATE = 'VAT_CERTIFICATE',
  VIDEO = 'VIDEO',
  EXTERNAL_IDENT_DOCUMENT = 'EXTERNAL_IDENT_DOCUMENT',
}

export enum AnswerType {
  TEXT_AND_FILES = 'TEXT_AND_FILES',
  TEXT_ONLY = 'TEXT_ONLY',
}

export enum RecipientType {
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
}
