import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  loading?: boolean;
}

const Button: FC<ButtonProps & { intl: IntlShape }> = (props) => {
  const {
    variant = ButtonVariant.PRIMARY,
    loading = false,
    onClick = () => {},
    children,
    disabled,
    className,
    intl: { formatMessage },
    ...otherProps
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading) return;
    onClick(e);
  };

  const content = () => {
    if (loading) {
      return <span>{formatMessage({ id: 'button.loading' })}</span>;
    } else {
      return children;
    }
  };

  const buttonBaseClass: string = 'button';
  const buttonClasses: string = classNames(
    buttonBaseClass,
    `${buttonBaseClass}--${variant}`,
    {
      [`${className}`]: !!className,
      [`${buttonBaseClass}--loading`]: loading,
    },
  );

  return (
    <div className={`${buttonBaseClass}__wrapper`}>
      <button
        className={buttonClasses}
        onClick={handleClick}
        disabled={disabled || loading}
        {...otherProps}
      >
        {content()}
      </button>
    </div>
  );
};

export default injectIntl(Button);
