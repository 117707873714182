import { FC, ReactNode } from 'react';
import Error from 'components/Error';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { LogoType } from '../Logo';

const Page: FC<{
  rootErrors?: string[];
  logoType?: LogoType | null;
  children?: ReactNode;
}> = (props) => {
  const { rootErrors, logoType, children } = props;
  return (
    <div className="page">
      {rootErrors && (
        <div>
          {rootErrors.map((message, i) => (
            <Error message={message} key={`error_message_${i}`} />
          ))}
        </div>
      )}
      <Header logoType={logoType} />
      <div className="page-box">{children}</div>
      <Footer />
    </div>
  );
};

export default Page;
