import { FC } from 'react';

const ADACLogo: FC<{}> = () => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      viewBox="0 0 80 28"
      fill="none"
      height="24px"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.93078 0.583313L0 27.4166H5.29179L6.39241 21.9898H12.2527L13.2465 27.4166H19.7101L14.3106 0.583313H5.93078ZM9.68912 6.41665H9.7591L11.5942 17.5H7.53623L9.68912 6.41665Z"
      />
      <path
        fill="#000"
        d="M28.4061 5.24998H28.9424C33.1943 5.24998 33.6235 10.1954 33.6235 13.3155C33.6235 16.8911 33.6235 22.75 29.1206 22.75H28.4061V5.24998ZM22.0293 27.4166H30.0131C38.0322 27.4166 41.1597 21.2363 41.1597 13.585C41.1597 5.7086 37.3336 0.583313 29.2034 0.583313H22.0293V27.4166Z"
      />
      <path
        fill="#000"
        d="M49.6883 6.41665H49.7595L51.5942 17.5H47.5362L49.6883 6.41665ZM40 27.4166H45.6026L46.7686 21.9896H52.9725L54.0252 27.4166H60.8696L55.1529 0.583313H46.2797L40 27.4166Z"
      />
      <path
        fill="#000"
        d="M73.889 9.95144C73.889 8.13523 73.453 4.99477 71.0883 4.99477C68.3251 4.99477 67.9249 9.04376 67.9249 14.0377C67.9249 19.5616 68.1795 23.0052 71.2348 23.0052C73.6352 23.0052 74.1802 20.9621 74.325 17.5947L79.9996 18.1239C79.9996 24.0264 76.6173 28 70.9436 28C63.0142 28 60.8691 20.9994 60.8691 13.9996C60.8691 7.52983 62.9784 0 70.9436 0C77.1989 0 79.9996 4.38937 79.9996 9.95144H73.889Z"
      />
    </svg>
  );
};

export default ADACLogo;
