import { FC, useEffect } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import { BaseInputProps, inputBaseClassName } from 'components/InputField';

export const testId = 'text-input';

type TextInputProps = BaseInputProps;

const TextInput: FC<TextInputProps & { intl: IntlShape }> = (props) => {
  const {
    id,
    placeholder,
    value,
    hasError,
    register,
    setIsCustomValid,
    onChange,
    onBlur,
    intl: { formatMessage },
  } = props;

  useEffect(() => {
    //we rely on the native validation here
    setIsCustomValid && setIsCustomValid(true);
  }, [setIsCustomValid]);

  return (
    <input
      id={id}
      className={classNames(inputBaseClassName, {
        [`${inputBaseClassName}--errored`]: hasError,
      })}
      placeholder={placeholder || formatMessage({ id: 'input.placeholder' })}
      data-testid={testId}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      {...(register && register(`${id}.response`, { required: true }))}
    />
  );
};

export default injectIntl(TextInput);
