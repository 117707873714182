export enum AppEnv {
  Testing = 'Testing',
  Staging = 'Staging',
  Sandbox = 'Sandbox',
  Production = 'Production',
}

export const appEnvironment = (): AppEnv => {
  const domain = window.location.host;

  switch (true) {
    case domain.includes('staging'):
      return AppEnv.Staging;
    case domain.includes('sandbox'):
      return AppEnv.Sandbox;
    case domain.includes('production'):
      return AppEnv.Production;
    default:
      return AppEnv.Testing;
  }
};

export enum SentryDSN {
  Testing = 'https://c466978e09f1f838851f90ef6f8a11b9@o617258.ingest.us.sentry.io/4507543041933312',
  Staging = 'https://6d3dfbebcca93853bc859dfed63de565@o617258.ingest.us.sentry.io/4507543041933312',
  Sandbox = 'https://d5c8171b900679c495e5bdcebfcc354b@o617258.ingest.us.sentry.io/4507543041933312',
  Production = 'https://56c23483468b25adac13d051bdee778b@o617258.ingest.us.sentry.io/4507543041933312',
}

export function environmentApiUrl() {
  if (window.location.host.includes('localhost')) {
    return 'https://customercommunicationui.testing.identity-afc.aws.solarisbank.io/v1';
  } else {
    return `${window.location.origin}/v1`;
  }
}

export function environmentSpecificSentryDSN(): SentryDSN {
  return SentryDSN[appEnvironment()];
}
