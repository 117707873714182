import { FC } from 'react';
import classNames from 'classnames';

const baseClassName = 'progress-bar';

interface ProgressBarProps {
  totalStepCount: number;
  currentStepIndex: number;
}

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { totalStepCount, currentStepIndex } = props;

  const renderFragments = () =>
    Array.from({ length: totalStepCount }, (_, i) => {
      return (
        <div
          key={i}
          className={classNames(`${baseClassName}__fragment`, {
            [`${baseClassName}__fragment--active`]: i <= currentStepIndex,
          })}
          style={{ width: Math.floor(100 / totalStepCount) + '%' }}
          data-testid={`${
            i <= currentStepIndex ? 'active-' : ''
          }progress-bar-fragment`}
        ></div>
      );
    });

  return <div className={`${baseClassName}`}>{renderFragments()}</div>;
};

export default ProgressBar;
