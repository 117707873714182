import { FC } from 'react';
import classNames from 'classnames';
import ADACLogo from 'components/Logo/components/ADACLogo';
import SolarisLogo from 'components/Logo/components/SolarisLogo';
import SolarisLogoIconOnly from 'components/Logo/components/SolarisLogoIconOnly';

export enum LogoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}
export enum LogoType {
  SolarisIconOnly = 'solarisIconOnly',
  Solaris = 'solaris',
  ADAC = 'adac',
}
interface LogoProps {
  size?: LogoSize;
  type: LogoType;
  inline?: boolean;
}
const Logo: FC<LogoProps> = (props) => {
  const { size = 'medium', type, inline = false } = props;
  const baseClassName = 'logo';

  const logoComponent = () => {
    switch (type) {
      case LogoType.SolarisIconOnly:
        return <SolarisLogoIconOnly />;
      case LogoType.Solaris:
        return <SolarisLogo />;
      case LogoType.ADAC:
        return <ADACLogo />;
    }
  };

  return (
    <div
      data-testid={type}
      className={classNames(`${baseClassName} ${baseClassName}--${size}`, {
        [`${baseClassName}--inline`]: inline,
      })}
    >
      {logoComponent()}
    </div>
  );
};

export default Logo;
