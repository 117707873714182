import axios from 'axios';
import { environmentApiUrl } from 'environment';
import { RequestConfig } from 'types';

declare module 'axios' {
  interface AxiosRequestConfig {
    shouldRetryOnAuthError?: boolean;
  }
}

export function request<T>(requestConfig: RequestConfig): Promise<T | void> {
  const {
    method,
    path,
    requestBody,
    shouldRetryOnAuthError = true,
    successCallback,
    errorCallback,
  } = requestConfig;
  return axios({
    method,
    url: `${environmentApiUrl()}${path}`,
    data: requestBody,
    headers: { 'Cache-Control': 'no-cache' },
    shouldRetryOnAuthError,
  }).then(
    (response) => {
      successCallback && successCallback(response.data);
      return response.data;
    },
    (error) => {
      const errMessage =
        error.response?.data?.title ||
        error.response?.data?.error ||
        'Unknown error';
      if (
        error.response?.status === 401 &&
        error.config.shouldRetryOnAuthError
      ) {
        request({ ...requestConfig, shouldRetryOnAuthError: false });
      } else if (errorCallback) {
        errorCallback(errMessage);
      } else {
        console.log(`An unhandled error occured: ${errMessage}`);
      }
    },
  );
}
