import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon/Icon';

const endScreenBaseClassName = 'end-screen';

const EndScreen: FC<{ intl: IntlShape }> = ({ intl: { formatMessage } }) => {
  return (
    <div className={`${endScreenBaseClassName}`}>
      <Icon
        className={`${endScreenBaseClassName}__icon`}
        type={IconType.CircleCheck}
        size={IconSize.BIG}
        color={IconColor.GREEN}
      />
      <div className={`${endScreenBaseClassName}__heading`}>
        {formatMessage({ id: 'endScreen.thankYou' })}
      </div>
      <div className={`${endScreenBaseClassName}__text`}>
        {formatMessage({ id: 'endScreen.message' })}
      </div>
    </div>
  );
};

export default injectIntl(EndScreen);
