import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { request } from 'api';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon';
import Loader, { LoaderColor, LoaderSize } from 'components/Loader';
import {
  camelToKebabCase,
  flattenObjectWithAppendedKeys,
} from 'lib/format-helper';
import { LogoType } from '../Logo';

export interface InfoResponse {
  styles?: CustomStyle;
  language: string | null;
}

export interface CustomStyle {
  color?: {
    stageBackground?: string;
    primary?: string;
    secondary?: string;
    errorState?: string;
    successState?: string;
    infoState?: string;
    greyDark?: string;
    greyMedium?: string;
    greyLight?: string;
    activePrimary?: string;
    activeSecondary?: string;
  };
  button?: {
    borderRadius?: string;
    borderWidth?: string;
    color?: string;
    hoverBackgroundColor?: string;
    hoverBorderColor?: string;
    hoverColor: string;
  };
  generalBorderRadius?: string;
  headerFontFamily?: string;
  fontFamily?: string;
  fontColor?: string;
  logo?: LogoType;
}

export interface ThemeProviderProps {
  children: ReactElement;
  setLogoType: (logo?: string) => void;
  setLocale: (locale?: string) => void;
}

const ThemeProvider: FC<ThemeProviderProps & { intl: IntlShape }> = ({
  children,
  setLogoType,
  setLocale,
  intl: { formatMessage },
}) => {
  const [infoResponse, setInfoResponse] = useState<InfoResponse | null>(null);
  const [isInfoCallFinished, setIsInfoCallFinished] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  // only load style on first render
  useEffect(() => {
    request<InfoResponse>({
      method: 'get',
      path: '/info',
      successCallback: (data: InfoResponse) => {
        setInfoResponse(data);
        setIsInfoCallFinished(true);
      },
      errorCallback: setError,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // set new styles once info call finished and the state changes
  const setStyle = (key: string, value: string) =>
    document.body.style.setProperty(`--theme-${key}`, value);

  useEffect(() => {
    if (infoResponse?.styles) {
      const { logo, ...rest } = infoResponse?.styles;
      const theme = flattenObjectWithAppendedKeys(rest);
      Object.keys(theme).forEach((key: string) =>
        setStyle(camelToKebabCase(key)!, theme[key]),
      );
      setLogoType(logo);
    }

    infoResponse?.language
      ? setLocale(infoResponse?.language)
      : setLocale('DE');
  }, [infoResponse, setLogoType, setLocale]);

  const isInErrorState = () => !!error;
  const isLoading = () => !isInfoCallFinished && !isInErrorState();

  return (
    <Fragment>
      {isInErrorState() && (
        <div className="theme-provider-wrapper__error">
          <Icon
            type={IconType.ExclamationTriangle}
            color={IconColor.RED}
            size={IconSize.BIG}
          />
          {error}
        </div>
      )}
      {isLoading() && (
        <div className="theme-provider-wrapper--loading">
          <Loader size={LoaderSize.BIG} color={LoaderColor.GREY} />
          <p className="theme-provider-wrapper__text">
            {formatMessage({ id: 'page.loading' })}
          </p>
        </div>
      )}
      {isInfoCallFinished && children}
    </Fragment>
  );
};

export default injectIntl(ThemeProvider);
