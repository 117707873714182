import { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { request } from 'api';
import Page from 'components/Page';
import { QuestionData } from 'modules/enhanced-due-diligence/components/Question';
import EndScreen from 'modules/enhanced-due-diligence/pages/EndScreen';
import Questionnaire from 'modules/enhanced-due-diligence/pages/Questionnaire';
import StartScreen from 'modules/enhanced-due-diligence/pages/StartScreen';
import Loader, { LoaderColor, LoaderSize } from '../../../../components/Loader';
import { LogoType } from '../../../../components/Logo';

enum Step {
  start,
  questionnaire,
  end,
}

export interface QuestionSet {
  question_set_deadline: string;
  questions: QuestionData[];
}

const Root: FC<{
  rootErrors?: string[];
  logoType?: LogoType | null;
}> = ({ rootErrors, logoType }) => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.start);
  const [questionDeadline, setQuestionDeadline] = useState<string>('');
  const [questionsData, setQuestionsData] = useState<QuestionData[] | null>(
    null,
  );
  const [areQuestionsLoading, setAreQuestionsLoading] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setErrors([...errors, ...(rootErrors || [])]);
    loadQuestionsOnPageLoad();
  }, [rootErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadQuestionsOnPageLoad = () => {
    setAreQuestionsLoading(true);

    const successCallback = (questionSet: QuestionSet) => {
      if (
        Array.isArray(questionSet.questions) &&
        questionSet.questions.length === 0
      ) {
        setCurrentStep(Step.end);
      }
      setQuestionDeadline(questionSet.question_set_deadline);
      setQuestionsData(questionSet.questions);
    };

    const errorCallback = (errMessage: string) =>
      setErrors([...errors, errMessage]);

    request<QuestionData[]>({
      method: 'get',
      path: '/questions',
      successCallback,
      errorCallback,
    }).finally(() => {
      setAreQuestionsLoading(false);
    });
  };
  const onStartSubmit = () => setCurrentStep(Step.questionnaire);

  return (
    <Fragment>
      {areQuestionsLoading ? (
        <div className="authentication-provider-wrapper--loading">
          <Loader size={LoaderSize.BIG} color={LoaderColor.GREY} />
          <p className="authentication-provider-wrapper__text">
            <FormattedMessage id="page.loading" />
          </p>
        </div>
      ) : (
        <Page rootErrors={errors} logoType={logoType}>
          {currentStep === Step.start && (
            <StartScreen
              onSubmit={onStartSubmit}
              isLoading={areQuestionsLoading}
              questionSetDeadline={questionDeadline}
            />
          )}
          {currentStep === Step.questionnaire && (
            <Questionnaire
              questions={questionsData!}
              onSubmit={() => setCurrentStep(Step.end)}
            />
          )}
          {currentStep === Step.end && <EndScreen />}
        </Page>
      )}
    </Fragment>
  );
};

export default Root;
