import { Dictionary } from 'types';

export const en: Dictionary<string> = {
  'page.loading': "Give us a moment, we're getting ready for you...",
  'heading.verifyYourIdentity': 'Collection of additional information',
  'startScreen.subTitle':
    'We ask for your support in answering further questions about yourself.',
  'startScreen.message':
    'We are required by European and national anti-money laundering regulations to collect additional information about you. This is necessary to continue providing you with the ADAC credit card and all associated services. Therefore, we ask you to:',
  'startScreen.step1':
    'Answer all the questions truthfully after starting the questionnaire.',
  'startScreen.step2':
    'Have any necessary documents ready, such as an ID card, passport, or proof of address.',
  'startScreen.step3': "Start the process by clicking on 'Begin' below.",
  'startScreen.step4':
    'Once the provided information has been reviewed, the process is complete, and you do not need to take any further action.',
  'startScreen.info':
    '<b>IMPORTANT:</b> If you do not submit your information by <b>{dateTime}</b>, we will unfortunately have to block your ADAC credit card.',
  'endScreen.thankYou': 'Thank you!',
  'endScreen.message':
    'We have received all the required information from you. We will contact you once we have reviewed your details or if we need further information.',
  'button.submitAndProceed': 'Submit and Proceed',
  'button.loading': 'Loading',
  'button.start': 'Begin',
  'info.submitButton':
    "Please note that after confirming via the 'Confirm and Continue' function, no changes to your information will be possible.",
  'input.placeholder': 'Please type an answer',
  'input.fileUpload': 'Click here to upload',
  'input.fileUploadHidden': 'Add more files',
  'input.fileUploadError': '{errorMessage}. Please retry. ',
  'input.fileUploadRetry': 'Retry?',
  'error.token': 'Authentication failed: {errorMessage}',
  'footer.poweredBy': 'Powered by',
};
