import { FC } from 'react';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon';

const baseClassName = 'error';

export interface ErrorProps {
  message: string;
}

const Error: FC<ErrorProps> = ({ message }) => {
  return (
    <div className={`${baseClassName}-box`}>
      <Icon
        type={IconType.ExclamationTriangle}
        color={IconColor.RED}
        size={IconSize.SMALL}
      />
      <span>{message}</span>
    </div>
  );
};

export default Error;
