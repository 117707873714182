import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Button from 'components/Button';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon/Icon';
import dayjs from 'dayjs';

export interface StartScreenProps {
  onSubmit: () => void;
  isLoading: boolean;
  questionSetDeadline: string;
}

const startScreenBaseClassName = 'start-screen';

const StartScreen: FC<StartScreenProps & { intl: IntlShape }> = ({
  onSubmit,
  isLoading,
  questionSetDeadline,
  intl: { formatMessage },
}) => {
  return (
    <div>
      <div className={`${startScreenBaseClassName}__heading`}>
        {formatMessage({ id: 'startScreen.subTitle' })}
      </div>
      <div className={`${startScreenBaseClassName}__text`}>
        {formatMessage({ id: 'startScreen.message' })}
      </div>
      <ol className={`${startScreenBaseClassName}__list`}>
        <li>
          <span>{formatMessage({ id: 'startScreen.step1' })}</span>
        </li>
        <li>
          <span>{formatMessage({ id: 'startScreen.step2' })}</span>
        </li>
        <li>
          <span>{formatMessage({ id: 'startScreen.step3' })}</span>
        </li>
      </ol>
      <div className={`${startScreenBaseClassName}__text`}>
        {formatMessage({ id: 'startScreen.step4' })}
      </div>
      <div className={`${startScreenBaseClassName}__info-wrapper`}>
        <Icon
          type={IconType.Info}
          size={IconSize.MEDIUM}
          color={IconColor.GREY}
          className={`${startScreenBaseClassName}__info-icon`}
        />
        <span className={`${startScreenBaseClassName}__info`}>
          {formatMessage(
            { id: 'startScreen.info' },
            {
              dateTime: dayjs(questionSetDeadline).format('DD.MM.YYYY, HH:mm'),
              b: (chunks) => <strong>{chunks}</strong>,
            },
          )}
        </span>
      </div>
      <Button onClick={onSubmit} loading={isLoading}>
        {formatMessage({ id: 'button.start' })}
      </Button>
    </div>
  );
};

export default injectIntl(StartScreen);
