import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { request } from 'api';
import Icon, { IconColor, IconSize, IconType } from '../Icon';
import Loader, { LoaderColor, LoaderSize } from '../Loader';

interface AuthenticationProviderProps {
  children: ReactElement;
  errorCallback?: (err: string) => void;
}

const AuthenticationProvider: FC<
  AuthenticationProviderProps & { intl: IntlShape }
> = (props) => {
  const {
    children,
    errorCallback,
    intl: { formatMessage },
  } = props;

  const formattedErrorCallback = (errorMessage: string) =>
    errorCallback &&
    errorCallback(formatMessage({ id: 'error.token' }, { errorMessage }));
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const params = new URLSearchParams(window.location.search);
  const requestBody = new FormData();
  requestBody.append('token', params.get('token')!);

  useEffect(() => {
    request({
      method: 'post',
      path: '/question_sets/access_token',
      requestBody: requestBody,
      shouldRetryOnAuthError: true,
      successCallback: () => setIsUserAuthenticated(true),
      errorCallback: (errMessage) => {
        formattedErrorCallback(errMessage);
        setError(errMessage);
      },
    });
  }, []);

  const isInErrorState = () => !!error;
  const isLoading = () => !isUserAuthenticated && !isInErrorState();

  return (
    <Fragment>
      {isInErrorState() && (
        <div className="authentication-provider-wrapper__error">
          <Icon
            type={IconType.ExclamationTriangle}
            color={IconColor.RED}
            size={IconSize.BIG}
          />
          {error}
        </div>
      )}
      {isLoading() && (
        <div className="authentication-provider-wrapper--loading">
          <Loader size={LoaderSize.BIG} color={LoaderColor.GREY} />
          <p className="authentication-provider-wrapper__text">
            {formatMessage({ id: 'page.loading' })}
          </p>
        </div>
      )}
      {isUserAuthenticated && children}
    </Fragment>
  );
};

export default injectIntl(AuthenticationProvider);
