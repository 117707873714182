import { FC } from 'react';
import { Control, useForm } from 'react-hook-form';
import Error from 'components/Error';
import FileInput from 'components/InputField/components/FileInput';
import TextInput from 'components/InputField/components/TextInput';
import { AllowedDocumentTypes, AnswerType } from 'types';

export interface InputFieldProps {
  id: string;
  answerType: AnswerType;
  error?: string;
  register: ReturnType<typeof useForm>['register'];
  setIsCustomValid: (value: boolean) => void;
  control: Control;
  allowedDocumentTypes?: AllowedDocumentTypes[];
}

const InputField: FC<InputFieldProps> = (props) => {
  const {
    answerType,
    id,
    error,
    control,
    register,
    setIsCustomValid,
    allowedDocumentTypes,
  } = props;

  const inputComponentProps = {
    id,
    control,
    register,
    setIsCustomValid,
    hasError: Boolean(error),
    allowedDocumentTypes,
  };

  const component = () => {
    switch (answerType) {
      case AnswerType.TEXT_ONLY:
        return <TextInput {...inputComponentProps} />;
      case AnswerType.TEXT_AND_FILES:
        return <FileInput {...inputComponentProps} />;
    }
  };

  return (
    <div>
      {component()}
      {error && <Error message={error} />}
    </div>
  );
};

export default InputField;
