import { FC } from 'react';
import classNames from 'classnames';

const baseClassName = 'loader';
export const loaderTestId = 'loader';

export enum LoaderSize {
  BIG = 'big',
}

export enum LoaderColor {
  GREY = 'grey',
}

interface LoaderProps {
  size?: LoaderSize;
  color?: LoaderColor;
  className?: string;
}

const Loader: FC<LoaderProps> = ({ size, color, className }) => {
  const loaderClassName = classNames(baseClassName, {
    [`${className}`]: !!className,
    [`${baseClassName}--${color}`]: !!color,
    [`${baseClassName}--${size}`]: !!size,
  });

  return <div data-testid={loaderTestId} className={loaderClassName}></div>;
};

export default Loader;
