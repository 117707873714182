import { FC } from 'react';

const SolarisLogoIconOnly: FC<{}> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="100 100 280 280"
    >
      <path
        fill="#EE6700"
        d="M246,162c-18.2,0-32.6,11.3-32.6,29.6,0,15.6,11.3,26.5,27.7,33.4l24.6,10.4c19.9,8.4,36.2,23.6,36.2,45.1a87,87,0,0,1-1.4,13.5,2.19,2.19,0,0,0,1.3,2.6,1.09,1.09,0,0,0,.5.1,2.27,2.27,0,0,0,1.8-.9,80.88,80.88,0,0,0,19.6-52.9c.1-42.7-35-80.9-77.7-80.9m-62.8,27.2a2.27,2.27,0,0,0-1.8.9A80.88,80.88,0,0,0,161.8,243c0,42.8,35.1,81,77.8,81,18.2,0,32.6-11.3,32.6-29.6,0-15.5-11.3-26.5-27.7-33.5l-24.6-10.4c-19.9-8.4-36.2-23.6-36.2-45.1a86.93,86.93,0,0,1,1.3-13.5,2.19,2.19,0,0,0-1.3-2.6,4.33,4.33,0,0,1-.5-.1"
      />
    </svg>
  );
};

export default SolarisLogoIconOnly;
