import { formatMoney } from 'accounting';

export function integersOnly(amount?: string | number): string {
  return amount ? amount.toString().replace(/[^-\d]/g, '') : '';
}

export function formatToAmount(inputValue: string | number): number | '' {
  return inputValue || inputValue === 0 ? Number(integersOnly(inputValue)) : '';
}

// This formatter is used to display formatted amount values in lists, data boxes and amount input fields
export function formatAmount(amount?: string | null): string {
  if (amount === undefined || amount === '' || amount === null) return '';

  return formatMoney(parseInt(amount.toString(), 10) / 100, {
    format: '%v',
  });
}

export const camelToKebabCase = (
  str: string | null | undefined,
): string | null => {
  if (!str) return null;
  return str
    .replace(/[a-z0-9](?=[A-Z])/g, (matchedValue) => `${matchedValue}-`)
    .toLowerCase();
};

export const flattenObjectWithAppendedKeys = (
  obj: {} | null,
  keyPrefix?: string,
) => {
  const flattenedObj = {};

  obj &&
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      const transformedKey = !!keyPrefix
        ? `${keyPrefix}${key.slice(0, 1).toUpperCase()}${key.slice(1)}`
        : key;
      if (typeof value === 'object') {
        Object.assign(
          flattenedObj,
          flattenObjectWithAppendedKeys(value, transformedKey),
        );
      } else {
        flattenedObj[transformedKey] = value;
      }
    });

  return flattenedObj;
};
