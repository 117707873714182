import { Dictionary } from 'types';

export const de: Dictionary<string> = {
  'page.loading':
    'Geben Sie uns einen Moment, wir bereiten alles für Sie vor...',
  'heading.verifyYourIdentity': 'Erfassung weiterführender Informationen',
  'startScreen.subTitle':
    'Wir bitten Sie um Ihre Unterstützung bei der Beantwortung weiterer Fragen zu Ihrer Person.',
  'startScreen.message':
    'Wir sind durch europäische sowie nationale geldwäscherechtliche Vorgaben verpflichtet, zusätzliche Informationen zu Ihrer Person zu erfassen. Dies ist notwendig, um Ihnen weiterhin die ADAC Kreditkarte mit allen damit verbundenen Dienstleistungen zur Verfügung stellen zu können. Daher bitten wir Sie um Folgendes:',
  'startScreen.step1':
    'Nach Start des Fragebogens beantworten Sie bitte alle Fragen zu Ihrer Person wahrheitsgemäß.',
  'startScreen.step2':
    'Halten Sie eventuell erforderliche Dokumente bereit, z. B. einen Personalausweis, Reisepass oder Adressnachweis.',
  'startScreen.step3':
    'Starten Sie den Prozess, indem Sie unten auf ‘Beginn’ klicken',
  'startScreen.step4':
    'Sobald die angegebenen Informationen überprüft wurden, ist der Prozess abgeschlossen. Sie müssen nichts weiter tun.',
  'startScreen.info':
    '<b>WICHTIG:</b> Falls Sie Ihre Angaben nicht bis zum <b>{dateTime} Uhr</b> eingereicht haben, müssen wir Ihre ADAC Kreditkarte leider sperren.',
  'endScreen.thankYou': 'Vielen Dank!',
  'endScreen.message':
    'Wir haben alle derzeit benötigten Informationen von Ihnen erhalten. Wir kontaktieren Sie, sobald Ihre Angaben von uns geprüft wurden, oder falls wir weitere Informationen von Ihnen benötigen sollten.',
  'button.submitAndProceed': 'Bestätigen und Weiter',
  'button.loading': 'Lädt',
  'button.start': 'Beginn',
  'info.submitButton':
    "Bitte beachten Sie, dass nach Bestätigung über die Funktion 'Bestätigen und Weiter' keine Änderung Ihrer Angaben mehr möglich ist.",
  'input.placeholder': 'Geben Sie hier Ihre Antwort ein',
  'input.fileUpload': 'Hier klicken, um Dateien hochzuladen',
  'input.fileUploadHidden': 'Weitere Dateien hinzufügen',
  'input.fileUploadError': '{errorMessage}. Bitte versuchen Sie es erneut. ',
  'input.fileUploadRetry': 'Jetzt nochmal versuchen?',
  'error.token': 'Authentifizierung ist fehlgeschlagen: {errorMessage}',
  'footer.poweredBy': 'Powered by',
};
