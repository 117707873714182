import { FC, SVGProps } from 'react';

const TrashCan: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5294 4.00006H1"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.64746 8.5V14.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8828 8.5V14.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.118 4V18.25C15.118 18.4489 15.0436 18.6397 14.9112 18.7803C14.7789 18.921 14.5993 19 14.4121 19H3.11799C2.93078 19 2.75124 18.921 2.61886 18.7803C2.48648 18.6397 2.41211 18.4489 2.41211 18.25V4"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2942 4V2.5C12.2942 2.10218 12.1454 1.72064 11.8807 1.43934C11.6159 1.15804 11.2568 1 10.8824 1H6.64712C6.27269 1 5.91361 1.15804 5.64885 1.43934C5.38409 1.72064 5.23535 2.10218 5.23535 2.5V4"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashCan;
