import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl, IntlShape } from 'react-intl';
import { request } from 'api';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { AllowedDocumentTypes, AnswerType } from 'types';

const questionBaseClassName = 'question';

export interface QuestionData {
  id: string;
  question: string;
  answer_type: AnswerType;
  deadline: string;
  allowed_document_types: AllowedDocumentTypes[];
  answer: {
    ready_for_review: boolean;
  };
}

export interface QuestionProps {
  data: QuestionData;
  onSubmit: () => void;
}

export interface FormValues {
  response?: string;
  attachments?: string[];
}

const Question: FC<QuestionProps & { intl: IntlShape }> = ({
  data,
  onSubmit,
  intl: { formatMessage },
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const [isCustomValid, setIsCustomValid] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<{
    [key: string]: FormValues;
  }>({ mode: 'onChange', shouldUnregister: true });

  const onFormSubmit = (input: { [key: string]: FormValues }) => {
    setIsSubmitLoading(true);

    const successCallback = () => {
      reset();
      onSubmit();
    };

    const { id } = data;
    const requestBody = { ready_for_review: true, ...input[id] };

    request<QuestionData>({
      method: 'post',
      path: `/questions/${id}/answer`,
      requestBody,
      successCallback,
      errorCallback: setSubmitError,
    }).finally(() => {
      setIsSubmitLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={`${questionBaseClassName}__text`}>{data.question}</div>
      <InputField
        id={data.id}
        register={register}
        control={control}
        answerType={data.answer_type}
        allowedDocumentTypes={data.allowed_document_types}
        error={submitError}
        setIsCustomValid={setIsCustomValid}
      />
      <div className={`${questionBaseClassName}__submit-info-wrapper`}>
        <span className={`${questionBaseClassName}__submit-info`}>
          {formatMessage({ id: 'info.submitButton' })}
        </span>
      </div>
      <Button loading={isSubmitLoading} disabled={!isValid || !isCustomValid}>
        {formatMessage({ id: 'button.submitAndProceed' })}
      </Button>
    </form>
  );
};

export default injectIntl(Question);
