import { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import Button from 'components/Button';
import Icon, { IconColor, IconSize, IconType } from 'components/Icon';
import { FileObject } from 'components/InputField/components/FileInput';
import Loader from 'components/Loader';

const baseClassName = 'file-entry';

export interface FileEntryProps {
  fileObject: FileObject;
  fileKey: string;
  onRemoveFile: (key: string) => void;
  onRetry: (key: string, file: File) => void;
}

const FileEntry: FC<FileEntryProps & { intl: IntlShape }> = (props) => {
  const {
    fileObject: { file, isLoading, error },
    fileKey,
    onRemoveFile,
    onRetry,
    intl: { formatMessage },
  } = props;

  const isInErrorState: boolean = !!error;

  const iconComponent = () => {
    if (isLoading) {
      return <Loader />;
    } else if (isInErrorState) {
      return (
        <Icon
          type={IconType.ExclamationTriangle}
          size={IconSize.MEDIUM}
          color={IconColor.RED}
        />
      );
    } else {
      return (
        <Icon
          type={IconType.CircleCheck}
          size={IconSize.MEDIUM}
          color={IconColor.GREEN}
        />
      );
    }
  };

  return (
    <div className={classNames(baseClassName)}>
      <div
        className={classNames(`${baseClassName}__element`, {
          [`${baseClassName}--uploading`]: isLoading,
          [`${baseClassName}--errored`]: isInErrorState,
        })}
      >
        {iconComponent()}
        <div>
          {file.name}
          {error && (
            <div className={classNames(`${baseClassName}__error`)}>
              {formatMessage(
                { id: 'input.fileUploadError' },
                { errorMessage: error },
              )}
              <span
                className={classNames(`${baseClassName}__retry`)}
                onClick={() => onRetry(fileKey, file)}
              >
                {formatMessage({ id: 'input.fileUploadRetry' })}
              </span>
            </div>
          )}
        </div>
      </div>
      <Button
        disabled={isLoading}
        type="reset"
        onClick={() => onRemoveFile(fileKey)}
        className={`${baseClassName}__delete-button`}
      >
        <Icon type={IconType.TrashCan} size={IconSize.MEDIUM} />
      </Button>
    </div>
  );
};

export default injectIntl(FileEntry);
